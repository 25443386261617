import { Category } from '@/lib/strapi-types/Category';

import { UseFindEntitiesProps } from '@/lib/types/data';
import { BackEndpoints } from '@/lib/types/enums/backEndpoints.enum';

export async function useFindCategories(
  { filters, populate, fields, pagination }: UseFindEntitiesProps = {},
  { isDisabled, limitJourneys }: { isDisabled?: boolean; limitJourneys?: boolean } = {}
) {
  if (isDisabled) {
    return { categories: undefined };
  }
  const { find } = useStrapi<Category['attributes']>();

  const defaultPopulate = {};
  const allPopulate = { ...defaultPopulate, ...populate };

  const { data: categoriesData } = await useAsyncData(
    `${limitJourneys ? BackEndpoints.CATEGORIES_LIMITED : BackEndpoints.CATEGORIES}-${JSON.stringify(filters)}`,
    () =>
      find(limitJourneys ? BackEndpoints.CATEGORIES_LIMITED : BackEndpoints.CATEGORIES, {
        populate: allPopulate,
        filters,
        fields,
        pagination
      })
  );

  return { categories: categoriesData.value?.data };
}
